import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5867d95c = () => interopDefault(import('../pages/best-of-the-year.vue' /* webpackChunkName: "pages/best-of-the-year" */))
const _707b0fc1 = () => interopDefault(import('../pages/copyright.vue' /* webpackChunkName: "pages/copyright" */))
const _047673b4 = () => interopDefault(import('../pages/cp/index.vue' /* webpackChunkName: "pages/cp/index" */))
const _6d465e04 = () => interopDefault(import('../pages/games/index.vue' /* webpackChunkName: "pages/games/index" */))
const _5b4ec782 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _240350d0 = () => interopDefault(import('../pages/signin.vue' /* webpackChunkName: "pages/signin" */))
const _93cba306 = () => interopDefault(import('../pages/success.vue' /* webpackChunkName: "pages/success" */))
const _1913549f = () => interopDefault(import('../pages/termsofuse.vue' /* webpackChunkName: "pages/termsofuse" */))
const _5b9f7f43 = () => interopDefault(import('../pages/top-games.vue' /* webpackChunkName: "pages/top-games" */))
const _3ddb3980 = () => interopDefault(import('../pages/cp/blocked.vue' /* webpackChunkName: "pages/cp/blocked" */))
const _290e1526 = () => interopDefault(import('../pages/cp/desc.vue' /* webpackChunkName: "pages/cp/desc" */))
const _36556bcd = () => interopDefault(import('../pages/cp/token.vue' /* webpackChunkName: "pages/cp/token" */))
const _aa2592ee = () => interopDefault(import('../pages/d/_id.vue' /* webpackChunkName: "pages/d/_id" */))
const _996d862e = () => interopDefault(import('../pages/games/_id/index.vue' /* webpackChunkName: "pages/games/_id/index" */))
const _2373084c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/best-of-the-year",
    component: _5867d95c,
    name: "best-of-the-year"
  }, {
    path: "/copyright",
    component: _707b0fc1,
    name: "copyright"
  }, {
    path: "/cp",
    component: _047673b4,
    name: "cp"
  }, {
    path: "/games",
    component: _6d465e04,
    name: "games"
  }, {
    path: "/privacy",
    component: _5b4ec782,
    name: "privacy"
  }, {
    path: "/signin",
    component: _240350d0,
    name: "signin"
  }, {
    path: "/success",
    component: _93cba306,
    name: "success"
  }, {
    path: "/termsofuse",
    component: _1913549f,
    name: "termsofuse"
  }, {
    path: "/top-games",
    component: _5b9f7f43,
    name: "top-games"
  }, {
    path: "/cp/blocked",
    component: _3ddb3980,
    name: "cp-blocked"
  }, {
    path: "/cp/desc",
    component: _290e1526,
    name: "cp-desc"
  }, {
    path: "/cp/token",
    component: _36556bcd,
    name: "cp-token"
  }, {
    path: "/d/:id?",
    component: _aa2592ee,
    name: "d-id"
  }, {
    path: "/games/:id",
    component: _996d862e,
    name: "games-id"
  }, {
    path: "/",
    component: _2373084c,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
